<template>
  <router-view />
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, onBeforeMount, onUnmounted } from "vue";
import { mainStore } from "@/store/store.js";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
const router = useRouter();
const store = mainStore();


window.addEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    store.prompt = event;
});


store.checkDisplay();
store.getUserDevice();
store.isFbOrInst();

onBeforeMount(() => {
var userLang = navigator.language || navigator.userLanguage;

  if (userLang == "pt") {
    locale.value = "pt";
  } else if(userLang == "fr") {
    locale.value = "fr";
  }else{
    locale.value = "es";
  }
});

const startApp = () => {
  if (store.userDevice != "Android") {
    localStorage.setItem("showOffer", true);
    router.replace("/offer");
  }

  if (!localStorage.getItem("params")) {
    localStorage.setItem("params", window.location.search);
  }

  if (!localStorage.getItem("link")) {
    const offer_link = store.link + localStorage.getItem("params");
    localStorage.setItem("link", offer_link);
  }

  if (
    localStorage.getItem("showOffer") ||
    (store.isPwa && store.installed && store.isLink)
  ) {
    router.replace("/offer");
  } else {
    router.replace("/store");
  }
};

startApp();

 
</script>
<style lang="scss" scoped></style>
