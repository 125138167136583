import en from "./en.json";
import fr from "./fr.json";
import pt from "./fr.json";

export const defaultLocale = "en";

export const languages = {
  en,
  fr,
  pt,
};
