import { defineStore } from "pinia";
import { ref } from "vue";

export const mainStore = defineStore("main", () => {
  const prompt = null;
  const showStore = ref(false);
  const userDevice = ref(null);
  const isPwa = ref(null);
  const installed = ref(localStorage.getItem("installed") ?? false);
  const nAgt = navigator.userAgent;
  const link = "https://beniluuxen.com/3jDvrP";
  const isLink = localStorage.getItem("link");
  const checkInstOrFb = ref(false);

  const getUserDevice = () => {
    if (nAgt.indexOf("Android") !== -1) {
      userDevice.value = "Android";
    } else {
      userDevice.value = "IOS";
    }
  };

  const isFbOrInst = () => {
    if (nAgt.indexOf("instagram") > -1 || nAgt.indexOf("FB") > -1) {
      checkInstOrFb.value = true;
    }
  };

  const checkDisplay = () => {
    const display = ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) =>
        window.matchMedia("(display-mode: " + displayMode + ")").matches
    );
    return (isPwa.value = display);
  };

  const getUserBrowserName = () => {
    const test = regexp => {
      return regexp.test(navigator.userAgent);
    };
  
  
  
    if (test(/opr\//i) || !!window.opr) {
      return 'Opera';
    } else if (test(/edg/i)) {
      return 'Microsoft Edge';
    } else if (test(/chrome|chromium|crios/i)) {
      return 'Google Chrome';
    } else if (test(/firefox|fxios/i)) {
      return 'Mozilla Firefox';
    } else if (test(/safari/i)) {
      return 'Apple Safari';
    } else if (test(/trident/i)) {
      return 'Microsoft Internet Explorer';
    } else if (test(/ucbrowser/i)) {
      return 'UC Browser';
    } else if (test(/samsungbrowser/i)) {
      return 'Samsung Browser';
    } else {
      return 'Unknown browser';
    }
  };

  return {
    prompt,
    getUserBrowserName,
    checkInstOrFb,
    isFbOrInst,
    isLink,
    link,
    isPwa,
    userDevice,
    getUserDevice,
    showStore,
    checkDisplay,

    installed,
  };
});
